import Routing from "./routing/routing";
import './assets/css/stylesheet.css'
function App() {
  return (
    <>
    <div class="loadr-sec">
        <div id="nest">
            <span> </span>
        </div>
    </div>
    <Routing />

    </>
    
  );
}

export default App;
